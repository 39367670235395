import { dayjs } from '@futures.tw/dayjs';
import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
export function createRollStore() {
    const state = {
        rollRules: [],
        filter: {
            symbolRoot: '',
            contractMonth: '',
            datetimeGte: dayjs().subtract(7, 'day'),
            datetimeLte: dayjs().add(7, 'day'),
        },
        pagination: FrLogic.pagination({}),
    };
    const actions = {
        async reloadRollRules() {
            apirc.strategy
                .http('get', '/api/contractRollRules')
                .request({
                'datetime[gte]': store.filter.datetimeGte.startOf('day').format(),
                'datetime[lte]': store.filter.datetimeLte.endOf('day').format(),
                symbolRoot: store.filter.symbolRoot,
                previousContractMonth: store.filter.contractMonth,
                ...state.pagination.getHttpParams(),
            })
                .then(res => {
                store.rollRules = res.data || [];
                store.pagination.setPagination(res);
            });
        },
    };
    const store = proxy({
        ...state,
        ...actions,
    });
    return store;
}
